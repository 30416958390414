/* You can add global styles to this file, and also import other style files */

// html,
// body {
//   height: 100%;
// }
// body {
//   margin: 0;
//   font-family: Roboto, "Helvetica Neue", sans-serif;
// }
//
@import "app/sass/base/base";
@import "app/sass/abstracts/variables";
@import "~@ng-select/ng-select/themes/default.theme.css";
:ng-deep .cdk-overlay-pane .x {
  margin: 1rem -1rem !important;
  padding: 0 1rem !important;
}
// .cdk-overlay-pane .x {
//   margin: 1rem -1rem !important;
//   padding: 0 1rem !important;
// }
@import "ngx-toastr/toastr";

*:focus {
  outline: none;
  /* outline: 4px dotted #e67e22; */
  /* outline-offset: 8px; */
  box-shadow: 0 0 0 0.8rem #30455b00;
}

.table-box::-webkit-scrollbar {
  width: 0.8rem;
}

.table-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d9d9d9;
  border-radius: 0.4rem;
  height: 2rem;
}

.table-box::-webkit-scrollbar-thumb {
  background-color: $color-button-primary;
  // outline: 1px solid slategrey;
  border-radius: 4px;
}

.table-box {
  cursor: auto;
}

.table-box::-webkit-scrollbar-button,
.table-box::-webkit-scrollbar-track,
.table-box::-webkit-scrollbar-track-piece,
.table-box::-webkit-scrollbar-corner,
.table-box::-webkit-resizer {
  display: block;
  height: 3rem;
}

.example-container {
  height: 44.2rem;
  max-width: 100%;
  overflow: auto;
  padding: 0 0.5rem;
}

.chart-bubble-title {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 12px;
  display: block;
  max-width: 450px;
  min-width: 250px;
  white-space: normal !important;
  font-family: "Poppins", sans-serif !important;
}

.title-tooltip {
  color: #04030f;
  font-family: "Poppins", sans-serif !important;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 134.5%;
}

.chart-title {
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  margin: 16px 0 12px 0;
  display: block;
  max-width: 450px;
  min-width: 250px;
  white-space: normal !important;
  filter: brightness(0.5);
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 0.0178571429em;
}

.faq-img {
  p {
    white-space: break-spaces;
    // margin-bottom: 5rem;
    // font-weight: 400;
    text-align: left;
    // color: #04030f;

    font-size: 1.4rem;
    font-style: normal;
    // line-height: 114.5%;
    line-height: 18.75px;

    font-family: "Poppins", sans-serif !important;
    /* font-weight: 500; */
    /* margin: 16px 0 12px 0; */
    /* display: block; */
    /* max-width: 450px; */
    /* min-width: 250px; */
    white-space: normal !important;
  }

  strong {
    // font-weight: 400;
    text-align: left;
    // color: #04030f;

    font-size: 1.4rem;
    font-style: normal;
    // line-height: 114.5%;
    line-height: 18.75px;

    font-family: "Poppins", sans-serif !important;
    /* font-weight: 500; */
    /* margin: 16px 0 12px 0; */
    /* display: block; */
    /* max-width: 450px; */
    /* min-width: 250px; */
    white-space: normal !important;
    filter: brightness(0.5);
  }

  img {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-bottom: 1rem;
    // width: 100%;
  }
}

.faq-imgs {
  p {
    white-space: break-spaces;
    // margin-bottom: 5rem;
    // font-weight: 400;
    text-align: left;
    // color: #04030f;

    font-size: 1.4rem;
    font-style: normal;
    // line-height: 114.5%;
    line-height: 18.75px;

    font-family: "Poppins", sans-serif !important;
    /* font-weight: 500; */
    /* margin: 16px 0 12px 0; */
    /* display: block; */
    /* max-width: 450px; */
    /* min-width: 250px; */
    white-space: normal !important;
  }

  strong {
    // font-weight: 400;
    text-align: left;
    // color: #04030f;

    font-size: 1.4rem;
    font-style: normal;
    // line-height: 114.5%;
    line-height: 18.75px;

    font-family: "Poppins", sans-serif !important;
    /* font-weight: 500; */
    /* margin: 16px 0 12px 0; */
    /* display: block; */
    /* max-width: 450px; */
    /* min-width: 250px; */
    white-space: normal !important;
    filter: brightness(0.5);
  }

  img {
    width: 50%;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .ca {
    display: flex !important;
  }
}
